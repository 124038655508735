import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import {
  EditSubscription,
  RoleSubscription,
} from 'src/app/models/subscriptions.model';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { EditSubscriptionDialogComponent } from 'src/app/shared/edit-subscription-dialog/edit-subscription-dialog.component';
import { NewSubscriptionDialogComponent } from 'src/app/shared/new-subscription-dialog/new-subscription-dialog.component';

@Component({
  selector: 'dfarm-admin-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
  sub: Subscription = new Subscription();
  subscriptions$: Observable<RoleSubscription[]>;

  constructor(
    private readonly subscriptionsService: SubscriptionsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.subscriptions$ = this.subscriptionsService.getSubscriptions();
  }

  editSubscription(subscription?: EditSubscription): void {
    const dialogRef = this.dialog.open(EditSubscriptionDialogComponent, {
      data: subscription,
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.subscriptions$ = this.subscriptionsService.getSubscriptions();
    });
  }

  createSubscription(): void {
    const dialogRef = this.dialog.open(NewSubscriptionDialogComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.subscriptions$ = this.subscriptionsService.getSubscriptions();
    });
  }

  deleteSubscription(roleSubscription: RoleSubscription): void {
    const { username } = roleSubscription.user;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: `Biztosan törölni akarod a(z) ${username} felhasználó csomagját?`,
    });

    this.sub.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result !== 'delete') return;
        this.sub.add(
          this.subscriptionsService
            .deleteSubscription(roleSubscription.id)
            .subscribe(() => {
              this.subscriptions$ =
                this.subscriptionsService.getSubscriptions();
            })
        );
      })
    );
  }
}
