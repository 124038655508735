import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ILocalUser } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject: BehaviorSubject<ILocalUser>;
  private refreshTokenTimeout: any;
  public user: Observable<ILocalUser>;
  private baseUrl = environment.baseUrl;

  constructor(private router: Router, private http: HttpClient) {
    this.userSubject = new BehaviorSubject<ILocalUser>(null);
    this.user = this.userSubject.asObservable();
  }

  loginUser(email: string, password: string): Observable<any> {
    const url = this.baseUrl + 'admin/accounts/login';
    return this.http.post<any>(url, {
      email,
      password
    }, { withCredentials: true, observe: 'response' }).pipe(map(user => {
      this.userSubject.next(user.body);
      this.startRefreshTokenTimer();
      return user.body;
    }));
  }

  refreshToken() {
    const url = this.baseUrl + 'admin/accounts/refresh-token';
    return this.http.post<any>(url, {}, { withCredentials: true })
      .pipe(map((user) => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }));
  }

  logout() {
    const url = this.baseUrl + 'admin/accounts/logout';
    this.http.get(url, { withCredentials: true }).subscribe();
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(['/login']);
  }

  // Helpers
  public get userValue(): ILocalUser {
    return this.userSubject.value;
  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(
      () => this.refreshToken().subscribe(),
      timeout
    );
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
