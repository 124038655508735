export const WATERING = {
  LINEAR: 'linear',
  CENTER_PIVOT: 'center-pivot',
  CYLINDER_WATERING: 'cylinder-watering',
  UNDERGROUND_DRIPPER: 'underground-dripper',
} as const;

export interface WateringData {
  name: string;
  dateOfManufacture: Date;
  brand: string;
  dateOfInstallation: Date;
  capacity: number;
  userId: string;
}
