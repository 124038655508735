import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { LandsService } from '../../services/lands.service';

@Component({
  selector: 'dfarm-admin-lands',
  templateUrl: './lands.component.html',
  styleUrls: ['./lands.component.scss'],
})
export class LandsComponent implements OnInit {

  constructor(
    private readonly landsService: LandsService,
    private readonly router: Router,
    private readonly _snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // this.landsService.getLandsByUser().subscribe((lands) => {
    //   console.log(lands);
    // });
  }

}
