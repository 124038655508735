<h1>Új öntöző létrehozása</h1>
<form class="flex flex-col" [formGroup]="newWateringDeviceForm">
  <mat-form-field>
    <mat-label>Név</mat-label>
    <input [formControl]="formControls.name" matInput placeholder="Név">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Márka</mat-label>
    <input [formControl]="formControls.brand" matInput placeholder="Márka">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Kapacitás</mat-label>
    <input [formControl]="formControls.capacity" matInput placeholder="Kapacitás">
  </mat-form-field>
  <mat-form-field *ngIf="users$ | async as users">
    <mat-label>Felhasználó</mat-label>
    <mat-select [formControl]="formControls.userId">
      <mat-option *ngFor="let user of users" [value]="user.userId">
        {{ user.firstName }} {{ user.lastName }} - {{ user.username }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Gyártási év</mat-label>
    <input [formControl]="formControls.dateOfManufacture" matInput [matDatepicker]="dateOfManufacture">
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="dateOfManufacture"></mat-datepicker-toggle>
    <mat-datepicker #dateOfManufacture></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Teleptítési év</mat-label>
    <input [formControl]="formControls.dateOfInstallation" matInput [matDatepicker]="dateOfInstallation">
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="dateOfInstallation"></mat-datepicker-toggle>
    <mat-datepicker #dateOfInstallation></mat-datepicker>
  </mat-form-field>
</form>
<div class="flex justify-end">
  <button mat-raised-button color="primary" (click)="save()">Mentés</button>
</div>
