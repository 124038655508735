import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { Coordinate, Land } from '../../models/land.model';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import { environment } from '../../../environments/environment';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'dfarm-admin-map-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDialogClose,
  ],
  templateUrl: './map-dialog.component.html',
  styleUrl: './map-dialog.component.scss',
})
export class MapDialogComponent implements OnInit {
  map: mapboxgl.Map;
  draw: MapboxDraw;
  geolocate: mapboxgl.GeolocateControl;
  satelliteStreetsStyle = 'mapbox://styles/mapbox/satellite-streets-v11';
  lat: number = this.land.coordinates[0].latitude;
  lng: number = this.land.coordinates[0].longitude;

  fieldId: string = 'ASD-';

  GREEN_FIELD_COLOR: string = '#04E824';
  GREY_FIELD_COLOR: string = '#7F7F7F';

  constructor(
    public dialogRef: MatDialogRef<MapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public land: Land,
  ) {}

  ngOnInit(): void {
    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox.accessToken,
      container: 'map',
      style: this.satelliteStreetsStyle,
      zoom: 15,
      // pitch: 45,
      center: [this.lng, this.lat], // starting position
    });

    this.map.on('load', () => {
      this.addLandToMap(this.land.coordinates);
    });
  }

  private addLandToMap(coordinates: Coordinate[]): void {
    const mappedCoordinates = this.transformFieldCoordinatesToCoordinateArray(coordinates);
    const polygonCoordinates = this.createPolygonCoordinates(mappedCoordinates);
    const center = this.calculateCenterOfField(polygonCoordinates);

    center.properties.areaLabel = `${ this.land.name } - ${ this.land.areaSize } Ha`;

    this.createFieldPolygonSource(this.fieldId, polygonCoordinates);
    this.createFieldLabelSource(this.fieldId, center);

    this.removeFieldPolygonLayerFromMap(this.fieldId);
    this.removeFieldLabelFromMap(this.fieldId);
    this.addFieldPolygonLayerToMap(this.fieldId, true);
    this.addFieldLabelToMap(this.fieldId);

    this.flyToField(center);
  }

  private createFieldPolygonSource(fieldId: string, polygonCoordinates: any[][]): void {
    const fieldSourceId: string = fieldId + '-Source';

    // Add a data source containing GeoJSON data.
    this.map.addSource(fieldSourceId, {
      type: 'geojson',
      data: {
        properties: null,
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          // These coordinates outline selected field.
          coordinates: polygonCoordinates,
        },
      },
    });
  }

  private addFieldPolygonLayerToMap(fieldId: string, selected: boolean): void {
    const fieldSourceId: string = fieldId + '-Source';
    const fieldLayer: string = fieldId + '-Layer';
    const fieldOutlineLayer: string = fieldId + '-OutlineLayer';

    const fieldColor = selected ? this.GREEN_FIELD_COLOR : this.GREY_FIELD_COLOR;
    // Add a new layer to visualize the polygon.
    this.map.addLayer({
      id: fieldLayer,
      type: 'fill',
      source: fieldSourceId, // reference the data source
      layout: {},
      paint: {
        'fill-color': fieldColor, // green color fill
        'fill-opacity': 0.4,
      },
    });

    // Add an outline around the polygon.
    this.map.addLayer({
      id: fieldOutlineLayer,
      type: 'line',
      source: fieldSourceId,
      layout: {},
      paint: {
        'line-color': fieldColor,
        'line-width': 3,
      },
    });
  }

  private removeFieldPolygonLayerFromMap(fieldId: string): void {
    const fieldLayer: string = fieldId + '-Layer';
    const fieldOutlineLayer: string = fieldId + '-OutlineLayer';

    if (this.map.getLayer(fieldLayer)) {
      this.map.removeLayer(fieldLayer);
    }
    if (this.map.getLayer(fieldOutlineLayer)) {
      this.map.removeLayer(fieldOutlineLayer);
    }
  }

  private transformFieldCoordinatesToCoordinateArray(fieldCoordinates: Coordinate[]): any[][] {
    const coordinates = [[]];
    fieldCoordinates.forEach((coordinateViewModel: Coordinate) => {
      coordinates[0].push([coordinateViewModel.longitude, coordinateViewModel.latitude]);
    });

    return coordinates;
  }

  private createPolygonCoordinates(coordinates: any[][]): any[][] {
    const polygonCoordinates = [...coordinates];

    // Azért kell hozzáadni az első koordinátát a végére, hogy szépen bekeretezze a polygont
    polygonCoordinates[0].push(coordinates[0][0]);

    return polygonCoordinates;
  }

  private calculateCenterOfField(polygonCoordinates: any[][]): any {
    const center = turf.centroid({
      properties: null,
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        // These coordinates outline selected field.
        coordinates: polygonCoordinates,
      },
    });

    return center;
  }

  private flyToField(center: any): void {
    this.lng = center.geometry.coordinates[0];
    this.lat = center.geometry.coordinates[1];

    this.map.flyTo({
      center: [this.lng, this.lat],
      // offset: [this.mapOffset[0], this.mapOffset[1]],
      speed: 0.8,
      zoom: 15,
      curve: 0.6,
    });
  }

  private createFieldLabelSource(fieldId: string, center: any): void {
    const labelSourceId = fieldId + '-LabelSource';

    this.map.addSource(labelSourceId, {
      type: 'geojson',
      data: center,
    });
  }

  private addFieldLabelToMap(fieldId: string): void {
    const labelSourceId: string = fieldId + '-LabelSource';
    const labelLayer: string = fieldId + '-AreaLabelLayer';

    // Add a new layer to visualize the area label.
    this.map.addLayer({
      id: labelLayer,
      type: 'symbol',
      source: labelSourceId, // reference the data source
      layout: {
        'text-field': ['get', 'areaLabel'],
      },
      paint: {
        'text-color': 'white',
      },
    });
  }

  private removeFieldLabelFromMap(fieldId: string): void {
    const labelLayer: string = fieldId + '-AreaLabelLayer';

    if (this.map.getLayer(labelLayer)) {
      this.map.removeLayer(labelLayer);
    }
  }

}
