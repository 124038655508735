<h1>Előfizetés létrehozása</h1>
<form [formGroup]="createSubscriptionForm" class="form-group">
  <div class="custom-inputs">
    <label>Felhasználó</label>
    <select [formControl]="formControls.username">
      <option *ngFor="let user of users" [value]="user.username">
        {{ user.username }}
      </option>
    </select>
  </div>
  <div class="custom-inputs" *ngIf="packages$ | async as packages">
    <label>Csomag</label>
    <select [formControl]="formControls.packageId">
      <option *ngFor="let package of packages" [value]="package.id">
        {{ package.name }}
      </option>
    </select>
  </div>
  <div class="custom-inputs">
    <label>
      <input
        type="date"
        class="bg-transparent w-28 text-center text-sm"
        [formControl]="formControls.start"
        required
        [max]="formControls.expiration.value"
      />
    </label>
    -
    <label>
      <input
        type="date"
        class="bg-transparent w-28 text-center text-sm"
        [formControl]="formControls.expiration"
        required
        [min]="formControls.start.value"
      />
    </label>
  </div>
  <div class="actions">
    <button class="btn btn-outline-primary" (click)="onCancel()">Mégsem</button>
    <button class="btn btn-primary" (click)="onSubmit()">
      Előfizetés létrehozása
    </button>
  </div>
</form>
