import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CreateSubscription,
  EditSubscription,
  RoleSubscription,
} from '../models/subscriptions.model';
import { UserDropdown } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  getSubscriptions(): Observable<RoleSubscription[]> {
    const url = `${this.baseUrl}subscriptions`;

    return this.http.get<any>(url, { withCredentials: true });
  }

  createSubscription(
    createSubscription: CreateSubscription,
    users: UserDropdown[]
  ): Observable<any> {
    const selectedUsername = createSubscription.username;
    const selectedUser = users.find(
      (user) => user.username === selectedUsername
    );

    if (!selectedUser) {
      return;
    }

    const createSubscriptionDto: CreateSubscription = {
      ...createSubscription,
      user: {
        userId: selectedUser.userId,
        username: selectedUsername,
      },
    };

    const url = `${this.baseUrl}subscriptions`;
    return this.http.post<any>(url, createSubscriptionDto, {
      withCredentials: true,
    });
  }

  editSubscription(editSubscription: EditSubscription): Observable<any> {
    const url = `${this.baseUrl}subscriptions/${editSubscription.id}`;

    return this.http.put<any>(url, editSubscription, { withCredentials: true });
  }

  deleteSubscription(id: string): Observable<any> {
    const url = `${this.baseUrl}subscriptions/${id}`;

    return this.http.delete<any>(url, { withCredentials: true });
  }
}
