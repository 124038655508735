<div class="login__container">
  <!-- TODO: Formra átírni -->
  <div class="login__container__inner">
    <!-- <select (change)="selectChange($event)">
            <option value="hu">
                HU
            </option>
            <option value="en">
                EN
            </option>
            <option value="sk">
                SK
            </option>
            <option value="de">
                DE
            </option>
        </select> -->
    <div class="login__logo">dFarm Admin</div>
    <h1 class="login__title">Bejelentkezés</h1>
    <label class="login__label"> Email cím </label>
    <input name="email" type="email" [(ngModel)]="username" />
    <label class="login__label"> Jelszó </label>
    <input name="password" type="password" [(ngModel)]="password" />
    <button class="login__button" (click)="login()">Bejelentkezés</button>
  </div>
</div>
