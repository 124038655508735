<div class="actions">
  <button class="btn btn-outline-primary" (click)="createSubscription()">
    Előfizetés létrehozás
  </button>
</div>
<table
  class="table table-striped"
  *ngIf="subscriptions$ | async as subscriptions"
>
  <thead>
    <tr>
      <th scope="col">&nbsp;</th>
      <th scope="col">Felhasználó neve</th>
      <th scope="col">Csomag neve</th>
      <th scope="col">Előfizetés kezdete</th>
      <th scope="col" class="fit">Előfizetés vége</th>
      <th scope="col" class="fit">Utoljára módosítva</th>
      <th scope="col" class="fit">Módosító neve</th>
      <th scope="col">&nbsp;</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let subscription of subscriptions; index as i">
      <td scope="row">
        <strong>
          {{ i + 1 }}
        </strong>
      </td>
      <td>
        {{ subscription.user.username }}
      </td>
      <td>
        {{ subscription.packageName }}
      </td>
      <td>{{ subscription.start | date : "yyyy.MM.dd. HH:mm" }}</td>
      <td>{{ subscription.expiration | date : "yyyy.MM.dd. HH:mm" }}</td>
      <td>
        {{ subscription.lastModification | date : "yyyy.MM.dd. HH:mm" }}
      </td>
      <td>{{ subscription.modifiedBy }}</td>
      <td>
        <div
          style="display: flex; gap: 8px"
          *ngIf="!subscription.deleted; else elseTemplate"
        >
          <button
            class="btn btn-primary"
            (click)="editSubscription(subscription)"
          >
            Módosítás
          </button>
          <button
            class="btn btn-danger"
            (click)="deleteSubscription(subscription)"
          >
            Törlés
          </button>
        </div>
        <ng-template #elseTemplate>
          <div>Törölve</div>
        </ng-template>
      </td>
    </tr>
  </tbody>
</table>
