import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Coordinate, Land } from '../models/land.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LandsService {
  private readonly baseUrl = environment.baseUrl;
  private readonly mapboxToken = environment.mapbox.accessToken;

  constructor(private http: HttpClient) {}

  getLandsByUser(userId: string): Observable<Land[]> {
    const url = this.baseUrl + 'admin/lands/' + userId;

    return this.http.get<any[]>(url, { withCredentials: true });
  }

  getFieldLocation(coordinate: Coordinate): Observable<string> {
    const longitude: number = coordinate.longitude;
    const latitude: number = coordinate.latitude;
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${ longitude },${ latitude }.json?access_token=${ this.mapboxToken }`;
    return this.http.get<any>(url).pipe(map((location) => location.features[0].place_name));
  }
}
