import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WATERING, WateringData } from '../../../models/watering.model';
import { WateringDataTableComponent } from '../../../shared/watering-data-table/watering-data-table.component';
import { MatButton } from '@angular/material/button';
import { WateringDeviceService } from '../../../services/watering-device.service';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'dfarm-admin-watering-page',
  standalone: true,
  imports: [
    CommonModule,
    WateringDataTableComponent,
    MatButton,
  ],
  templateUrl: './watering-page.component.html',
  styleUrl: './watering-page.component.scss',
})
export class WateringPageComponent implements OnInit {
  title: string;
  // wateringData: WateringData[] = [];
  wateringData$: Observable<WateringData[]>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly wateringDeviceService: WateringDeviceService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const url = window.location.href; // az aktuális URL lekérése
      if (url.includes(WATERING.LINEAR)) {
        this.title = 'Linear';
        this.wateringData$ = this.wateringDeviceService.getLinearWateringDevicesByUser();
      } else if (url.includes(WATERING.CENTER_PIVOT)) {
        this.title = 'Center Pivot';
      } else if (url.includes(WATERING.CYLINDER_WATERING)) {
        this.title = 'Cylinder Watering';
      } else if (url.includes(WATERING.UNDERGROUND_DRIPPER)) {
        this.title = 'Underground Dripper';
      }
    });
  }

  navigateToNewWateringDevicePage(): void {
    this.router.navigate(['watering', 'new-watering-device']);
  }
}
