<div class="p-8">
  <h1>
    Biztosan törölni akarod a(z)
    <strong>
      {{ data }}
    </strong>
    felhasználót?
  </h1>
  <div class="actions">
    <button mat-button color="primary" (click)="dialogRef.close()">Mégsem</button>
    <button mat-raised-button color="warn" cdkFocusInitial (click)="dialogRef.close('delete')">Törlés</button>
  </div>
</div>
