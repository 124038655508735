import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../guards/auth.guard';

import { SubscriptionsComponent } from 'src/app/components/subscriptions/subscriptions.component';
import { UserDetailsComponent } from 'src/app/components/user-details/user-details.component';
import { LandsComponent } from '../../components/lands/lands.component';
import { UsersComponent } from '../../components/users/users.component';
import { MainComponent } from './main.component';
import { WATERING } from '../../models/watering.model';
import { WateringPageComponent } from '../../components/watering/watering-page/watering-page.component';
import { NewWateringDeviceComponent } from '../../components/watering/new-watering-device/new-watering-device.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'users', pathMatch: 'full', component: UsersComponent },
      { path: 'users/:id', pathMatch: 'full', component: UserDetailsComponent },
      { path: 'lands', pathMatch: 'full', component: LandsComponent },
      { path: 'watering/' + WATERING.LINEAR, pathMatch: 'full', component: WateringPageComponent },
      { path: 'watering/' + WATERING.CENTER_PIVOT, pathMatch: 'full', component: WateringPageComponent },
      { path: 'watering/' + WATERING.CYLINDER_WATERING, pathMatch: 'full', component: WateringPageComponent },
      { path: 'watering/' + WATERING.UNDERGROUND_DRIPPER, pathMatch: 'full', component: WateringPageComponent },
      { path: 'watering/new-watering-device', pathMatch: 'full', component: NewWateringDeviceComponent },
      {
        path: 'subscriptions',
        pathMatch: 'full',
        component: SubscriptionsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
