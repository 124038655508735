import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { WateringData } from '../../../models/watering.model';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { WateringDeviceService } from '../../../services/watering-device.service';
import { UserService } from '../../../services/user.service';
import { Observable } from 'rxjs';
import { UserViewModel } from '../../../models/user.model';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'dfarm-admin-new-watering-device',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MatDatepickerModule,
    MatButton,
    MatSelectModule,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './new-watering-device.component.html',
  styleUrl: './new-watering-device.component.scss',
})
export class NewWateringDeviceComponent {
  private readonly wateringDeviceService: WateringDeviceService = inject(WateringDeviceService);
  private readonly userService: UserService = inject(UserService);

  users$: Observable<UserViewModel[]> = this.userService.getUsers();

  formControls: Record<keyof WateringData, FormControl> = {
    name: new FormControl('', Validators.required),
    dateOfManufacture: new FormControl(null, Validators.required),
    brand: new FormControl('', Validators.required),
    dateOfInstallation: new FormControl(null, Validators.required),
    capacity: new FormControl('', Validators.required),
    userId: new FormControl('', Validators.required),
  };
  newWateringDeviceForm = new FormGroup(this.formControls);

  save(): void {
    this.newWateringDeviceForm.markAllAsTouched();
    if (this.newWateringDeviceForm.valid) {
      this.wateringDeviceService.addNewWateringDevice(this.newWateringDeviceForm.value).subscribe();
    }
  }
}
