import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { RouterModule } from '@angular/router';
import { MainRoutingModule } from './main-routing.module';

import { SharedModule } from 'src/app/shared/shared.module';
import { MenuComponent } from '../../components/menu/menu.component';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { UsersComponent } from '../../components/users/users.component';
import {MatTableModule} from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatCellDef, MatHeaderCellDef, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MainComponent } from './main.component';

@NgModule({
  declarations: [
    MainComponent,
    NavbarComponent,
    MenuComponent,
    UsersComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    MainRoutingModule,
    MatTableModule,
    MatSort,
    MatPaginator,
    MatButton,
    MatSortModule,
    MatDivider,
    SharedModule,
  ],
})
export class MainModule { }
