<div class="p-8">
  <h1>Felhasználó email címe</h1>
  <mat-form-field class="w-full">
    <mat-label>Email</mat-label>
    <input [formControl]="emailFormControl" type="email" matInput>
  </mat-form-field>
  <div class="flex justify-between gap-2 items-center">
    <button class="flex-1" mat-button color="primary" (click)="dialogRef.close()">Mégse</button>
    <button class="flex-1" mat-raised-button color="primary" (click)="save()">Mentés</button>
  </div>
</div>
