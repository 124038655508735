<button class="mb-4" mat-raised-button color="primary" (click)="navigateBackToUsers()">Vissza a felhasználók listájára
</button>
<div *ngIf="user">
  <div class="user">
    <div>Username:</div>
    <div>{{ user.username }}</div>
  </div>
  <div class="user">
    <div>First name:</div>
    <div>{{ user.firstName }}</div>
  </div>
  <div class="user">
    <div>Last name:</div>
    <div>{{ user.lastName }}</div>
  </div>
  <div class="user">
    <div>Email:</div>
    <div>{{ user.email }}</div>
  </div>
  <div class="user">
    <div>Phone number:</div>
    <div>{{ user.phoneNumber }}</div>
  </div>
  <div class="user">
    <div>Is email confirmed:</div>
    <div>{{ user.emailConfirmed }}</div>
  </div>
  <div class="user">
    <div>Is terms and conditions accepted:</div>
    <div>{{ user.termsAndConditions }}</div>
  </div>
  <button class="mb-4" mat-raised-button color="warn" (click)="deleteUser(user.username)">Felhasználó törlése</button>
</div>
<div *ngIf="lands$ | async as lands" class="mat-elevation-z8">
  <table mat-table [dataSource]="lands">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th *matHeaderCellDef mat-header-cell>Név</th>
      <td *matCellDef="let row" mat-cell>
        {{ row.name }}
      </td>
    </ng-container>

    <!-- Area Size Location -->
    <ng-container matColumnDef="areaSize">
      <th *matHeaderCellDef mat-header-cell>Terület mérete</th>
      <td *matCellDef="let row" mat-cell>
        {{ row.areaSize }} ha
      </td>
    </ng-container>

    <!-- Location Column -->
    <ng-container matColumnDef="location">
      <th *matHeaderCellDef mat-header-cell>Hely</th>
      <td *matCellDef="let row" mat-cell>
        {{ row.location }}
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr
      *matRowDef="let row; columns: displayedColumns"
      class="cursor-pointer"
      (click)="showLandOnMap(row)"
      mat-row></tr>

    <!-- Row shown when there is no matching data. -->
    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell" colspan="4">A felhasználónak nincs földje</td>
    </tr>
  </table>

</div>
